import {
	type ActionFunctionArgs,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Form, useSearchParams } from '@remix-run/react'
import { MoonhubLogo } from '#app/components/logo'
import { Button } from '#app/components/ui/button'
import { Card, CardContent } from '#app/components/ui/card'
import { GradientText } from '#app/components/ui/text'
import { login, requireAnonymous } from '#app/utils/auth.server'

export const meta: MetaFunction = () => {
	return [
		{ title: 'Login' },
		{ name: 'description', content: 'Login to your account' },
	]
}

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	const url = new URL(request.url)
	const error = url.searchParams.get('error')
	return { error }
}

export async function action({ request }: ActionFunctionArgs) {
	// Handle login action
	const formData = await request.formData()
	const prompt = formData.get('prompt')?.toString()
	const screenHint = formData.get('screen_hint')?.toString()
	const inviteId = formData.get('invite_id')?.toString()

	const options = {
		...(prompt ? { prompt } : {}),
		...(screenHint ? { screen_hint: screenHint } : {}),
		...(inviteId ? { invite_id: inviteId } : {}),
	}

	return login(request, options)
}

export default function LoginWithLayout() {
	const [searchParams] = useSearchParams()

	// Get a specific query parameter
	const error = searchParams.get('error')
	const invite_id = searchParams.get('invite')

	return (
		<div className="bg-[#0a0a0a]">
			<div className="relative flex min-h-screen flex-col justify-between overflow-hidden">
				{/* Background image container with opacity */}
				<div
					className="absolute inset-0 z-0 bg-cover bg-center bg-no-repeat opacity-30"
					style={{
						backgroundImage: `url('/img/stargradient.png')`,
					}}
				/>

				<div className="relative z-10 flex-1">
					<main className="font-poppins grid h-screen place-items-center overflow-hidden p-10">
						<div className="landscape:space-10 grid w-full grid-cols-1 items-center gap-6 px-4 portrait:grid-cols-1 portrait:gap-6 md:portrait:gap-24 landscape:grid-cols-5 lg:landscape:gap-24 xl:landscape:gap-32">
							<div className="flex items-center justify-center portrait:h-[min(40vh,400px)] landscape:col-span-2 landscape:h-[min(70vh,600px)]">
								<img
									src="/img/moonhub-hero-helmet.png"
									alt="Moonhub"
									className="h-full w-auto object-contain"
								/>
							</div>
							<div className="flex flex-col items-center justify-center gap-6 portrait:items-center landscape:col-span-3 landscape:items-start">
								<MoonhubLogo className="animate-slide-left h-10 text-white [animation-delay:0.5s] [animation-fill-mode:backwards]" />
								<h1
									data-heading
									className="animate-slide-left text-foreground text-center text-6xl font-medium [animation-delay:0.8s] [animation-fill-mode:backwards] portrait:text-center portrait:text-[3rem] landscape:text-left"
								>
									<GradientText variant="moonhub" reverse>
										Autopilot, with Stella
									</GradientText>
								</h1>
								{error && (
									<Card className="flex w-full flex-1 rounded-2xl border-red-900 bg-red-600 text-lg text-white">
										<strong className="p-5 pb-0 text-xl font-semibold">
											Error
										</strong>
										<CardContent className="pt-0">
											{error}
										</CardContent>
									</Card>
								)}
								<Form method="post" className="mt-6">
									{invite_id && (
										<input
											type="hidden"
											name="invite_id"
											value={invite_id}
										/>
									)}
									<Button
										variant="default"
										type="submit"
										size="2xl"
									>
										Log In
									</Button>
								</Form>
							</div>
						</div>
					</main>
				</div>
			</div>
		</div>
	)
}
